import * as yup from "yup";
import difference from "lodash/difference";
import map from "lodash/map";
import values from "lodash/values";
import { isSameOrAfter, isSameOrBefore } from "../utils/dateTimeUtils";
import isValidNumberField from "../utils/isValidNumberField";
import { schemaNullable } from "../utils/yupUtils";
import {
  DATE_FORMAT,
  DATE_TIME_FORMAT,
  GREATER_OR_EQUAL_DATE_TIME_MSG,
  LESS_OR_EQUAL_DATE_TIME_MSG,
  TIME_FORMAT,
} from "./dateTimeConstants";

function minLengthLessOrEqualMaxLength(minLength) {
  return isValidNumberField(minLength, this.parent.maxLength);
}

function maxLengthGreaterOrEqualMinLength(maxLength) {
  return isValidNumberField(this.parent.minLength, maxLength);
}

function lessOrEqualDate(minValue) {
  const { maxValue } = this.parent;
  return maxValue && minValue ? isSameOrBefore(minValue, maxValue, DATE_FORMAT) : true;
}

function greaterOrEqualDate(maxValue) {
  const { minValue } = this.parent;
  return maxValue && minValue ? isSameOrAfter(maxValue, minValue, DATE_FORMAT) : true;
}

function lessOrEqualTime(minValue) {
  const { maxValue } = this.parent;
  return maxValue && minValue ? isSameOrBefore(minValue, maxValue, TIME_FORMAT) : true;
}

function greaterOrEqualTime(maxValue) {
  const { minValue } = this.parent;
  return maxValue && minValue ? isSameOrAfter(maxValue, minValue, TIME_FORMAT) : true;
}

function lessOrEqualDateTime(minValue) {
  const { maxValue } = this.parent;
  return maxValue && minValue ? isSameOrBefore(minValue, maxValue, DATE_TIME_FORMAT) : true;
}

function greaterOrEqualDateTime(maxValue) {
  const { minValue } = this.parent;
  return maxValue && minValue ? isSameOrAfter(maxValue, minValue, DATE_TIME_FORMAT) : true;
}

export const SELECT_FIELD_MODES = {
  default: "default",
  multiple: "multiple",
};

export const FIELD_TYPES = {
  heading: "heading",
  divider: "divider",
  content: "content",
  text: "text",
  password: "password",
  textarea: "textarea",
  select: "select",
  bool: "bool",
  date: "date",
  time: "time",
  datetime: "datetime",
  number: "number",
  currency: "currency",
  file: "file",
  image: "image",
  phone: "phone",
  email: "email",
  country: "country",
  language: "language",
  gender: "gender",
  group: "group",
};

export const FIELD_TYPES_LABELS = {
  [FIELD_TYPES.heading]: "Heading",
  [FIELD_TYPES.divider]: "Divider",
  [FIELD_TYPES.content]: "Content",
  [FIELD_TYPES.text]: "Text",
  [FIELD_TYPES.password]: "Password",
  [FIELD_TYPES.textarea]: "Text (Multiline)",
  [FIELD_TYPES.select]: "Select (Choices)",
  [FIELD_TYPES.bool]: "Yes / No",
  [FIELD_TYPES.date]: "Date",
  [FIELD_TYPES.time]: "Time",
  [FIELD_TYPES.datetime]: "Date and Time",
  [FIELD_TYPES.number]: "Number",
  [FIELD_TYPES.currency]: "Currency",
  [FIELD_TYPES.file]: "File",
  [FIELD_TYPES.image]: "Image",
  [FIELD_TYPES.phone]: "Phone",
  [FIELD_TYPES.email]: "Email",
  [FIELD_TYPES.country]: "Country",
  [FIELD_TYPES.language]: "Language",
  [FIELD_TYPES.gender]: "Gender",
  [FIELD_TYPES.group]: "Group",
};

export const FIELD_TYPE_SELECT_OPTIONS = map(FIELD_TYPES, value => ({
  value,
  label: FIELD_TYPES_LABELS[value],
}));

export const FIELD_TYPES_ALLOWING_VALUES = difference(values(FIELD_TYPES), [
  FIELD_TYPES.heading,
  FIELD_TYPES.divider,
  FIELD_TYPES.content,
]);

export const FIELD_TYPES_WITHOUT_META_CONFIG_VALUE = [FIELD_TYPES.heading, FIELD_TYPES.divider];

export const FIELD_APPLICABLE_META_OPTIONS = {
  label: {
    [FIELD_TYPES.heading]: true,
    [FIELD_TYPES.content]: true,
    [FIELD_TYPES.text]: true,
    [FIELD_TYPES.password]: true,
    [FIELD_TYPES.textarea]: true,
    [FIELD_TYPES.select]: true,
    [FIELD_TYPES.bool]: true,
    [FIELD_TYPES.date]: true,
    [FIELD_TYPES.time]: true,
    [FIELD_TYPES.datetime]: true,
    [FIELD_TYPES.number]: true,
    [FIELD_TYPES.currency]: true,
    [FIELD_TYPES.file]: true,
    [FIELD_TYPES.image]: true,
    [FIELD_TYPES.phone]: true,
    [FIELD_TYPES.email]: true,
    [FIELD_TYPES.country]: true,
    [FIELD_TYPES.language]: true,
    [FIELD_TYPES.gender]: true,
    [FIELD_TYPES.group]: true,
  },
  placeholder: {
    [FIELD_TYPES.text]: true,
    [FIELD_TYPES.textarea]: true,
    [FIELD_TYPES.select]: true,
    [FIELD_TYPES.date]: true,
    [FIELD_TYPES.time]: true,
    [FIELD_TYPES.datetime]: true,
    [FIELD_TYPES.number]: true,
    [FIELD_TYPES.currency]: true,
    [FIELD_TYPES.phone]: true,
    [FIELD_TYPES.email]: true,
    [FIELD_TYPES.country]: true,
    [FIELD_TYPES.language]: true,
    [FIELD_TYPES.group]: true,
  },
  helpText: {
    [FIELD_TYPES.text]: true,
    [FIELD_TYPES.password]: true,
    [FIELD_TYPES.textarea]: true,
    [FIELD_TYPES.select]: true,
    [FIELD_TYPES.bool]: true,
    [FIELD_TYPES.date]: true,
    [FIELD_TYPES.time]: true,
    [FIELD_TYPES.datetime]: true,
    [FIELD_TYPES.number]: true,
    [FIELD_TYPES.currency]: true,
    [FIELD_TYPES.file]: true,
    [FIELD_TYPES.image]: true,
    [FIELD_TYPES.phone]: true,
    [FIELD_TYPES.email]: true,
    [FIELD_TYPES.country]: true,
    [FIELD_TYPES.language]: true,
    [FIELD_TYPES.gender]: true,
    [FIELD_TYPES.group]: true,
  },
  required: {
    [FIELD_TYPES.text]: true,
    [FIELD_TYPES.textarea]: true,
    [FIELD_TYPES.select]: true,
    [FIELD_TYPES.bool]: true,
    [FIELD_TYPES.date]: true,
    [FIELD_TYPES.time]: true,
    [FIELD_TYPES.datetime]: true,
    [FIELD_TYPES.number]: true,
    [FIELD_TYPES.currency]: true,
    [FIELD_TYPES.file]: true,
    [FIELD_TYPES.image]: true,
    [FIELD_TYPES.phone]: true,
    [FIELD_TYPES.email]: true,
    [FIELD_TYPES.country]: true,
    [FIELD_TYPES.language]: true,
    [FIELD_TYPES.gender]: true,
    [FIELD_TYPES.group]: true,
  },
  choices: {
    [FIELD_TYPES.select]: true,
  },
  minLength: {
    [FIELD_TYPES.text]: true,
    [FIELD_TYPES.textarea]: true,
  },
  maxLength: {
    [FIELD_TYPES.text]: true,
    [FIELD_TYPES.textarea]: true,
  },
  minValue: {
    [FIELD_TYPES.date]: true,
    [FIELD_TYPES.time]: true,
    [FIELD_TYPES.datetime]: true,
    [FIELD_TYPES.number]: true,
    [FIELD_TYPES.currency]: true,
  },
  maxValue: {
    [FIELD_TYPES.date]: true,
    [FIELD_TYPES.time]: true,
    [FIELD_TYPES.datetime]: true,
    [FIELD_TYPES.number]: true,
    [FIELD_TYPES.currency]: true,
  },
  decimalPlaces: {
    [FIELD_TYPES.number]: true,
  },
  fileTypes: {
    [FIELD_TYPES.file]: true,
  },
  maxFiles: {
    [FIELD_TYPES.file]: true,
    [FIELD_TYPES.image]: true,
  },
  maxTotalFileSize: {
    [FIELD_TYPES.file]: true,
    [FIELD_TYPES.image]: true,
  },
  aspectRatio: {
    [FIELD_TYPES.image]: true,
  },
  whitelist: {
    [FIELD_TYPES.country]: true,
    [FIELD_TYPES.language]: true,
  },
  blacklist: {
    [FIELD_TYPES.country]: true,
    [FIELD_TYPES.language]: true,
  },
  mode: {
    [FIELD_TYPES.select]: true,
    [FIELD_TYPES.country]: true,
    [FIELD_TYPES.language]: true,
  },
  content: {
    [FIELD_TYPES.content]: true,
  },
};

export const FIELD_META_FIELDS = {
  label: {
    type: "text",
    label: "Label",
    required: true,
  },
  placeholder: {
    type: "text",
    label: "Placeholder",
  },
  helpText: {
    type: "textarea",
    label: "Help text",
  },
  required: {
    type: "bool",
    label: "Required",
  },
  choices: {
    type: "choices",
    label: "Choices",
    helpText: 'Add choices as "Value1, Value2" or "Value1:Label1, Value2:Label2"',
    mode: "tags",
  },
  minLength: {
    type: "number",
    label: "Min length",
    helpText: "Minimum number of characters (enforced only if a value has been entered)",
    decimalPlaces: 0,
  },
  maxLength: {
    type: "number",
    label: "Max length",
    helpText: "Maximum number of characters",
    decimalPlaces: 0,
  },
  minValue: {
    type: "text",
    label: "Min value",
    helpText: "Minimum value (enforced only if a value has been entered)",
  },
  maxValue: {
    type: "text",
    label: "Max value",
    helpText: "Maximum value",
  },
  decimalPlaces: {
    type: "number",
    label: "Decimal places",
    helpText: "Maximum number of decimal places allowed",
    decimalPlaces: 0,
  },
  fileTypes: {
    type: "select",
    label: "File type(s)",
    placeholder: "Select file type(s)",
    mode: SELECT_FIELD_MODES.multiple,
    options: [
      {
        value: "jpg",
        label: "JPEG/JPG",
      },
      {
        value: "png",
        label: "PNG",
      },
      {
        value: "pdf",
        label: "PDF",
      },
      {
        value: "doc",
        label: "DOC/DOCX (Word)",
      },
      {
        value: "xls",
        label: "XLS/XLSX (Excel)",
      },
      {
        value: "csv",
        label: "CSV",
      },
    ],
  },
  maxFiles: {
    type: "number",
    label: "Max files",
    helpText: "Maximum number of files",
    decimalPlaces: 0,
  },
  maxTotalFileSize: {
    type: "number",
    label: "Max size",
    helpText: "Maximum combined size of files (in MB)",
    decimalPlaces: 0,
    minValue: 1,
  },
  aspectRatio: {
    type: "text",
    label: "Aspect ratio",
  },
  whitelist: {
    type: "whitelist",
    label: "Whitelist",
    helpText: "Subset of values to include",
    mode: "tags",
  },
  blacklist: {
    type: "blacklist",
    label: "Blacklist",
    helpText: "Subset of values to exclude",
    mode: "tags",
  },
  mode: {
    type: "select",
    label: "Allow multiple?",
    required: true,
    options: [
      {
        value: SELECT_FIELD_MODES.default,
        label: "No (single choice)",
      },
      {
        value: SELECT_FIELD_MODES.multiple,
        label: "Yes (multiple choices allowed)",
      },
    ],
  },
  content: {
    type: "content",
    label: "Content",
    required: false,
  },
};

export const FIELD_META_SCHEMA = {
  type: yup.string(), // Mutating type required for meta-type validation processing
  label: yup.string().when("type", (type, schema) => {
    return type !== FIELD_TYPES.divider ? schema.required("Please enter a label.") : schema;
  }),
  placeholder: yup.string(),
  helpText: yup.string(),
  required: yup.bool(),
  choices: yup.array(),
  minLength: schemaNullable(
    yup
      .number()
      .positive("Minimum length must be a positive number.")
      .integer("Minimum length must be an integer.")
      .typeError("Minimum length must be a number.")
      .test(
        "lessOrEqualNumber",
        "Minimum length must be less than or equal to maximum length.",
        minLengthLessOrEqualMaxLength,
      ),
  ),
  maxLength: schemaNullable(
    yup
      .number()
      .positive("Maximum length must be a positive number.")
      .integer("Maximum length must be an integer.")
      .typeError("Maximum length must be a number.")
      .test(
        "greaterOrEqualNumber",
        "Maximum length must be greater than or equal to minimum length.",
        maxLengthGreaterOrEqualMinLength,
      ),
  ),
  minValue: yup.mixed().when("type", (type, schema) => {
    // The minValue field needs to be treated as a string when used in a string context, e.g. when setting a minValue for
    // a date field, and needs to be treated as a number when used in a number context, e.g. when setting a minValue for
    // a number field. The same applies to maxValue.
    if (type === FIELD_TYPES.date) {
      return yup.string().test("lessOrEqualDate", LESS_OR_EQUAL_DATE_TIME_MSG, lessOrEqualDate);
    }

    if (type === FIELD_TYPES.time) {
      return yup.string().test("lessOrEqualTime", LESS_OR_EQUAL_DATE_TIME_MSG, lessOrEqualTime);
    }

    if (type === FIELD_TYPES.datetime) {
      return yup.string().test("lessOrEqualDateTime", LESS_OR_EQUAL_DATE_TIME_MSG, lessOrEqualDateTime);
    }

    if (type === FIELD_TYPES.number) {
      return schemaNullable(
        yup
          .number()
          .min(0, "Minimum value must not be negative.")
          .integer("Minimum value must be an integer.")
          .typeError("Minimum value must be a number."),
      );
    }

    return schema;
  }),
  maxValue: yup.string().when("type", (type, schema) => {
    if (type === FIELD_TYPES.date) {
      return schema.test("greaterOrEqualDate", GREATER_OR_EQUAL_DATE_TIME_MSG, greaterOrEqualDate);
    }

    if (type === FIELD_TYPES.time) {
      return schema.test("greaterOrEqualTime", GREATER_OR_EQUAL_DATE_TIME_MSG, greaterOrEqualTime);
    }

    if (type === FIELD_TYPES.datetime) {
      return schema.test("greaterOrEqualDateTime", GREATER_OR_EQUAL_DATE_TIME_MSG, greaterOrEqualDateTime);
    }

    if (type === FIELD_TYPES.number) {
      return schemaNullable(
        yup
          .number()
          .positive("Maximum value must be a positive number.")
          .integer("Minimum value must be an integer.")
          .typeError("Minimum value must be a number.")
          .when("minValue", (minValue, _schema) => {
            return _schema.test({
              test: maxValue => isValidNumberField(minValue, maxValue),
              message: "Maximum value must be greater than or equal to minimum value.",
            });
          }),
      );
    }

    return schema;
  }),
  decimalPlaces: schemaNullable(
    yup
      .number()
      .min(0, "Decimal places must not be negative.")
      .integer("Decimal places must be an integer.")
      .typeError("Decimal places must be a number."),
  ),
  fileTypes: yup.array(),
  maxFiles: schemaNullable(
    yup
      .number()
      .positive("Max files must be a positive number.")
      .integer("Max files must be an integer.")
      .typeError("Max files must be a number."),
  ),
  maxTotalFileSize: schemaNullable(
    yup
      .number()
      .positive("Max size must be a positive number.")
      .integer("Max size must be an integer.")
      .typeError("Max size must be a number."),
  ),
  aspectRatio: yup.string(),
  whitelist: yup.array(),
  blacklist: yup.array(),
  content: yup.string(),
  mode: yup.string().when("type", (type, schema) => {
    return type === FIELD_TYPES.select ? schema.required("Please select a value.") : schema;
  }),
};

export const FIELD_TYPE_HELP_MESSAGE = {
  [FIELD_TYPES.datetime]: `${
    FIELD_TYPES_LABELS[FIELD_TYPES.datetime]
  } fields allow users to enter the date and time with reference to their profile time zone (if it has been set), or their local time offset (if their profile time zone has not been set).`,
};
