import { useEffect, useMemo } from "react";
import { useLocation, matchPath } from "react-router-dom";
import find from "lodash/find";
import isUndefined from "lodash/isUndefined";
import * as ROUTES from "../constants/routeConstants";

const DEFAULT_PAGE_TITLE = "Fusesport";

const PAGES = [
  // Events

  // // Competition Management
  {
    matchProps: { path: ROUTES.EVENT_SPORTMEET_COMPETITION_DIVISIONING, exact: true },
    title: "Divisioning - Manage Competition | fusesport",
  },
  {
    matchProps: { path: ROUTES.EVENT_SPORTMEET_COMPETITION_ROUNDS, exact: true },
    title: "Rounds - Manage Competition | fusesport",
  },
  {
    matchProps: { path: ROUTES.EVENT_SPORTMEET_COMPETITION_COMPETITORS, exact: true },
    title: "Competitors - Manage Competition | fusesport",
  },

  // // Competition
  {
    matchProps: { path: ROUTES.EVENT_SPORTMEET_COMPETITION_EDIT_TEAMS, exact: true },
    title: "Teams - Edit Competition | fusesport",
  },
  {
    matchProps: { path: ROUTES.EVENT_SPORTMEET_COMPETITION_EDIT, exact: true },
    title: "Settings - Edit Competition | fusesport",
  },
  {
    matchProps: { path: ROUTES.EVENT_SPORTMEET_COMPETITION_CREATE, exact: true },
    title: "Create Competition | fusesport",
  },

  // // Sportmeet
  {
    matchProps: { path: ROUTES.EVENT_SPORTMEET_EDIT, exact: true },
    title: "Edit Sport for Event | fusesport",
  },
  {
    matchProps: { path: ROUTES.EVENT_SPORTMEET_CREATE, exact: true },
    title: "Create Sport for Event | fusesport",
  },
  {
    matchProps: { path: ROUTES.EVENT_SPORTMEET_HOME_CREATE_SPORT, exact: true },
    title: "Select Sport for Event | fusesport",
  },
  {
    matchProps: { path: ROUTES.EVENT_SPORTMEET_HOME, exact: true },
    title: "Sport for Event | fusesport",
  },

  // // Event
  {
    matchProps: { path: ROUTES.EVENT_EDIT_PERMISSIONS, exact: true },
    title: "Permissions - Edit Event | fusesport",
  },
  {
    matchProps: { path: ROUTES.EVENT_EDIT, exact: true },
    title: "Settings - Edit Event | fusesport",
  },
  {
    matchProps: { path: ROUTES.EVENT_CREATE, exact: true },
    title: "Create Event | fusesport",
  },
  {
    matchProps: { path: ROUTES.EVENT_HOME, exact: true },
    title: "Event | fusesport",
  },
  {
    matchProps: { path: ROUTES.COMPETITION_MANAGEMENT, exact: true },
    title: "Events | fusesport",
  },

  // // Select create new model
  {
    matchProps: { path: ROUTES.COMPETITION_MANAGEMENT_CREATE, exact: true },
    title: "Create New Event | fusesport",
  },
  {
    matchProps: { path: ROUTES.EVENT_HOME_CREATE, exact: true },
    title: "Create New Sport for Event | fusesport",
  },
  {
    matchProps: { path: ROUTES.EVENT_SPORTMEET_COMPETITION_HOME_CREATE, exact: true },
    title: "Create New Competition | fusesport",
  },

  // Registrations

  // // Registrations
  {
    matchProps: { path: `${ROUTES.REGISTRATIONS}/:id/permissions`, exact: true },
    title: "Permissions - Edit Registration | fusesport",
  },
  {
    matchProps: { path: `${ROUTES.REGISTRATIONS}/:id/steps`, exact: true },
    title: "Steps - Edit Registration | fusesport",
  },
  {
    matchProps: { path: `${ROUTES.REGISTRATIONS}/:id/travel-groups`, exact: true },
    title: "Travel Groups - Edit Registration | fusesport",
  },
  {
    matchProps: { path: `${ROUTES.REGISTRATIONS}/:id/registrants`, exact: true },
    title: "Registrants - Edit Registration | fusesport",
  },
  {
    matchProps: { path: `${ROUTES.REGISTRATIONS}/:id/classic-sync`, exact: true },
    title: "Classic Sync - Edit Registration | fusesport",
  },
  {
    matchProps: { path: `${ROUTES.REGISTRATIONS}/:id`, exact: true },
    title: "Settings - Edit Registration | fusesport",
  },
  {
    matchProps: { path: ROUTES.REGISTRATIONS, exact: true },
    title: "Registrations | fusesport",
  },

  // // Forms
  {
    matchProps: { path: `${ROUTES.FORMS}/:id/fields`, exact: true },
    title: "Fields - Edit Form | fusesport",
  },
  {
    matchProps: { path: `${ROUTES.FORMS}/:id`, exact: true },
    title: "Settings - Edit Form | fusesport",
  },
  {
    matchProps: { path: ROUTES.FORMS, exact: true },
    title: "Forms | fusesport",
  },

  // People

  {
    matchProps: { path: `${ROUTES.PEOPLE}/:id/registrations`, exact: true },
    title: "Registrations - Person Profile | fusesport",
  },
  {
    matchProps: { path: `${ROUTES.PEOPLE}/:id/travel-groups`, exact: true },
    title: "Travel Groups - Person Profile | fusesport",
  },
  {
    matchProps: { path: `${ROUTES.PEOPLE}/:id/competitions`, exact: true },
    title: "Competitions - Person Profile | fusesport",
  },
  {
    matchProps: { path: `${ROUTES.PEOPLE}/:id/groups`, exact: true },
    title: "Memberships - Person Profile | fusesport",
  },
  {
    matchProps: { path: `${ROUTES.PEOPLE}/:id`, exact: true },
    title: "General - Person Profile | fusesport",
  },
  {
    matchProps: { path: ROUTES.PEOPLE, exact: true },
    title: "People | fusesport",
  },

  // Reports

  // // Summary Report
  {
    matchProps: { path: ROUTES.SUMMARY_REPORT, exact: true },
    title: "Summary Report | fusesport",
  },

  // // Discount Code Usage Report
  {
    matchProps: { path: ROUTES.DISCOUNT_CODE_USAGE_REPORT, exact: true },
    title: "Discount Code Usage Report | fusesport",
  },

  // // Discount Code Transaction Report
  {
    matchProps: { path: ROUTES.DISCOUNT_CODE_TRANSACTION_REPORT, exact: true },
    title: "Discount Code Transaction Report | fusesport",
  },
  
  // Download
  {
    matchProps: { path: ROUTES.DOWNLOAD, exact: true },
    title: "File Download | fusesport"
  },

  // Admin

  {
    matchProps: { path: ROUTES.DASHBOARD, exact: true },
    title: "Dashboard | fusesport",
  },
  {
    matchProps: { path: ROUTES.ROLES },
    title: "Roles | fusesport",
  },
  {
    matchProps: { path: ROUTES.GROUP_TYPES },
    title: "Group Types | fusesport",
  },
  {
    matchProps: { path: ROUTES.GROUPS },
    title: "Groups | fusesport",
  },
  {
    matchProps: { path: ROUTES.RESULT_STATUSES },
    title: "Result Statuses | fusesport",
  },
  {
    matchProps: { path: ROUTES.SPORTS },
    title: "Sports | fusesport",
  },
  {
    matchProps: { path: ROUTES.FORMATS },
    title: "Formats | fusesport",
  },
  {
    matchProps: { path: ROUTES.SCORE_TYPES },
    title: "Score Types | fusesport",
  },
  {
    matchProps: { path: ROUTES.FIELDS },
    title: "Custom Fields | fusesport",
  },

  {
    matchProps: { path: `${ROUTES.DISCOUNT_CODES}/:key`, exact: true },
    title: "Discount Codes | fusesport",
  },

  {
    matchProps: { path: ROUTES.USER_GROUP_ROLE_PERMISSIONS },
    title: "Permission Management | fusesport",
  },

  // Profile ( user profile )

  {
    matchProps: { path: ROUTES.PROFILE, exact: true },
    title: "Person Profile | fusesport",
  },

  // My Registrations

  {
    matchProps: { path: ROUTES.MY_REGISTRATIONS_CURRENT_REGISTRATIONS, exact: true },
    title: "Current Registrations - My Registrations | fusesport",
  },
  {
    matchProps: { path: ROUTES.MY_REGISTRATIONS_COMPLETED_REGISTRATIONS, exact: true },
    title: "Completed Registrations - My Registrations | fusesport",
  },
  {
    matchProps: { path: ROUTES.MY_REGISTRATIONS_COMPETITIONS, exact: true },
    title: "Competitions - My Registrations | fusesport",
  },
  {
    matchProps: { path: ROUTES.MY_REGISTRATIONS_COMPLETED_PROFILE, exact: true },
    title: "Profile - My Registrations | fusesport",
  },

  // User registration

  {
    matchProps: { path: ROUTES.USER_REGISTRATIONS, exact: false },
    title: "User Registration | fusesport",
  },
];

export default function useDocumentTitles() {
  const { pathname } = useLocation();

  const pageTitle = useMemo(() => {
    const page = find(PAGES, item => matchPath(pathname, item.matchProps) !== null);

    if (!isUndefined(page)) {
      return page.title;
    }

    return DEFAULT_PAGE_TITLE;
  }, [pathname]);

  useEffect(() => {
    document.title = pageTitle;
  }, [pageTitle]);
}
