import React from "react";
import { Layout } from "antd";
import { Route, Switch } from "react-router-dom";
import * as ROUTES from "../constants/routeConstants";
import useDocumentTitles from "../utils/useDocumentTitle";
import { PERMISSION } from "../utils/useGetUserHasPermission";
import Confirm from "../auth/Confirm";
import Login from "../auth/Login";
import Reset from "../auth/Reset";
import { useAuth0 } from "./Auth0";
import NavHeader from "./GlobalNav/NavHeader";
import PrivateRoute from "./PrivateRoute";
import RegistrationUser from "./SelfRegistration/RegistrationUser";
import SpinFullScreen from "./SpinFullScreen";
import UnsupportedBrowserAlert from "./UnsupportedBrowserAlert";
import "./App.scss";

const CompetitionManagement = React.lazy(() => import("./CompetitionManagement/CompetitionManagement"));
const CountryListPage = React.lazy(() => import("./MenuAdmin/Countries/CountryListPage"));
const Dashboard = React.lazy(() => import("../container/Dashboard/Dashboard"));
const DiscountCodeTransactionReportPage = React.lazy(() =>
  import("./Reports/DiscountCodeTransactionReport/DiscountCodeTransactionReportPage"),
);
const DiscountCodeUsageReportPage = React.lazy(() =>
  import("./Reports/DiscountCodeUsageReport/DiscountCodeUsageReportPage"),
);
const FieldListPage = React.lazy(() => import("./MenuAdmin/Fields/FieldListPage"));
const FormatListPage = React.lazy(() => import("./MenuAdmin/Formats/FormatListPage"));
const FormDetailPage = React.lazy(() => import("./MenuAdmin/Forms/FormDetailPage"));
const FormFieldTestPage = React.lazy(() => import("./FormFields/FormFieldTestPage"));
const FormListPage = React.lazy(() => import("./MenuAdmin/Forms/FormListPage"));
const GroupListPage = React.lazy(() => import("./MenuAdmin/Groups/GroupListPage"));
const GroupTypeListPage = React.lazy(() => import("./MenuAdmin/GroupTypes/GroupTypeListPage"));
const HomePage = React.lazy(() => import("./HomePage"));
const LogOutPage = React.lazy(() => import("./LogOutPage"));
const NoMatchPage = React.lazy(() => import("./NoMatchPage"));
const PersonListPage = React.lazy(() => import("./MenuPeople/PersonList/PersonListPage"));
const PersonProfilePage = React.lazy(() => import("./MenuPeople/PersonProfilePage"));
const RegistrationDetailPage = React.lazy(() => import("./MenuAdmin/Registrations/RegistrationDetailPage"));
const RegistrationListPage = React.lazy(() => import("./MenuAdmin/Registrations/RegistrationListPage"));
const ResultStatusListPage = React.lazy(() => import("./MenuAdmin/ResultStatuses/ResultStatusListPage"));
const RoleListPage = React.lazy(() => import("./MenuAdmin/Roles/RoleListPage"));
const ScoreTypeListPage = React.lazy(() => import("./MenuAdmin/ScoreTypes/ScoreTypeListPage"));
const SportListPage = React.lazy(() => import("./MenuAdmin/Sports/SportListPage"));
const SummaryReportPage = React.lazy(() => import("./Reports/SummaryReportPage"));
const UserGroupRolePermissionPage = React.lazy(() => import("./MenuAdmin/Permissions/UserGroupPermissionRolePage"));
const TenantListPage = React.lazy(() => import("./MenuAdmin/FuseSportAdmin/TenantManagement/TenantListPage"));
const DiscountCodeList = React.lazy(() => import("./MenuAdmin/DiscountCodes/DiscountCodeList"));
const DiscountCodeEditForm = React.lazy(() => import("./MenuAdmin/DiscountCodes/DiscountCodeEditForm"));
const DiscountCodeCreateForm = React.lazy(() => import("./MenuAdmin/DiscountCodes/DiscountCodeCreateForm"));
const SystemSettingsTheme = React.lazy(() => import("./MenuAdmin/SystemSettings/SystemSettingsTheme"));
const DownloadExportedFilesPage = React.lazy(() => import("./Downloads/DownloadExportedFilesPage"));
const PrivateFilePage = React.lazy(() => import("./PrivateFile/PrivateFilePage"));
const RegistrationPaymentConfirmation = React.lazy(() => import("./SelfRegistration/RegistrationPaymentConfirmation"));

function getPath(routeList, routeCreate) {
  return [routeCreate, `${routeList}/:id`, routeList];
}

function App() {
  useDocumentTitles();
  const { loading: authLoading } = useAuth0();

  return (
    <Layout className="app">
      {!authLoading && <NavHeader authLoading={authLoading} />}

      <main style={{ marginTop: 64, flex: 1, flexShrink: 0, display: "flex", flexDirection: "column" }}>
        {authLoading && <SpinFullScreen />}

        {!authLoading && (
          <>
            <React.Suspense fallback={<SpinFullScreen />}>
              <Layout.Content style={{ flex: 1, flexShrink: 0, display: "flex", flexDirection: "column" }}>
                <UnsupportedBrowserAlert />

                <Switch>
                  <Route exact path="/" component={props => <HomePage {...props} />} />

                  {/* Login, New User Registration, Reset Password */}
                  <Route path={ROUTES.LOGIN} component={props => <Login pagemode="LOGIN" {...props} />} />
                  <Route
                    path={ROUTES.REGISTER_NEW_USER}
                    component={props => <Login pagemode="REGISTER" {...props} />}
                  />
                  <Route path="/logout" component={props => <LogOutPage {...props} />} />
                  <Route path="/reset" component={props => <Reset {...props} />} />
                  <Route path="/confirm" component={props => <Confirm {...props} />} />

                  {/* Open Route to Registration Form for Users */}
                  <Route path={ROUTES.USER_REGISTRATIONS} component={RegistrationUser} />
                  <Route path={ROUTES.USER_PAYMENT_AWAIT} component={RegistrationPaymentConfirmation} />

                  {/* Dashboard */}

                  <PrivateRoute
                    path={ROUTES.DASHBOARD}
                    component={props => <Dashboard {...props} />}
                    permission={PERMISSION.ACCESS_DASHBOARD}
                  />

                  {/* Profile  */}
                  <PrivateRoute
                    path={ROUTES.PROFILE}
                    component={props => <PersonProfilePage {...props} />}
                    permission={PERMISSION.ACCESS_PROFILE}
                  />

                  {/* People tab */}
                  <PrivateRoute
                    path={[`${ROUTES.PEOPLE}/:id`, ROUTES.PEOPLE]}
                    component={props => <PersonListPage {...props} />}
                    permission={PERMISSION.ACCESS_PEOPLE}
                  />

                  {/* Registrations tab  */}
                  <PrivateRoute
                    exact
                    path={ROUTES.REGISTRATIONS}
                    component={props => <RegistrationListPage {...props} />}
                    permission={PERMISSION.ACCESS_REGISTRATIONS}
                  />
                  <PrivateRoute
                    path={`${ROUTES.REGISTRATIONS}/:id`}
                    component={props => <RegistrationDetailPage {...props} />}
                    permission={PERMISSION.ACCESS_REGISTRATIONS}
                  />
                  <PrivateRoute
                    exact
                    path={ROUTES.FORMS}
                    component={props => <FormListPage {...props} />}
                    permission={PERMISSION.ACCESS_FORMS}
                  />
                  <PrivateRoute
                    path={`${ROUTES.FORMS}/:id`}
                    component={props => <FormDetailPage {...props} />}
                    permission={PERMISSION.ACCESS_FORMS}
                  />

                  {/* Competition Management */}
                  <PrivateRoute
                    path={ROUTES.COMPETITION_MANAGEMENT}
                    component={props => <CompetitionManagement {...props} />}
                    permission={PERMISSION.ACCESS_COMPETITION_MANAGEMENT}
                  />

                  {/* Admin Menus */}
                  <PrivateRoute
                    path={getPath(ROUTES.FIELDS, ROUTES.FIELD_CREATE)}
                    component={props => <FieldListPage {...props} />}
                    permission={PERMISSION.TENANT_ADMIN_MENUS}
                  />
                  <PrivateRoute
                    path={getPath(ROUTES.COUNTRIES, ROUTES.COUNTRY_CREATE)}
                    component={props => <CountryListPage {...props} />}
                    permission={PERMISSION.TENANT_ADMIN_MENUS}
                  />
                  <PrivateRoute
                    path={getPath(ROUTES.ROLES, ROUTES.ROLE_CREATE)}
                    component={props => <RoleListPage {...props} />}
                    permission={PERMISSION.TENANT_ADMIN_MENUS}
                  />
                  <PrivateRoute
                    path={getPath(ROUTES.GROUP_TYPES, ROUTES.GROUP_TYPE_CREATE)}
                    component={props => <GroupTypeListPage {...props} />}
                    permission={PERMISSION.TENANT_ADMIN_MENUS}
                  />
                  <PrivateRoute
                    path={getPath(ROUTES.GROUPS, ROUTES.GROUP_CREATE)}
                    component={props => <GroupListPage {...props} />}
                    permission={PERMISSION.TENANT_ADMIN_MENUS}
                  />
                  <PrivateRoute
                    path={getPath(ROUTES.SPORTS, ROUTES.SPORT_CREATE)}
                    component={props => <SportListPage {...props} />}
                    permission={PERMISSION.TENANT_ADMIN_MENUS}
                  />
                  <PrivateRoute
                    path={getPath(ROUTES.RESULT_STATUSES, ROUTES.RESULT_STATUS_CREATE)}
                    component={props => <ResultStatusListPage {...props} />}
                    permission={PERMISSION.TENANT_ADMIN_MENUS}
                  />
                  <PrivateRoute
                    path={getPath(ROUTES.FORMATS, ROUTES.FORMAT_CREATE)}
                    component={props => <FormatListPage {...props} />}
                    permission={PERMISSION.TENANT_ADMIN_MENUS}
                  />
                  <PrivateRoute
                    path={getPath(ROUTES.SCORE_TYPES, ROUTES.SCORE_TYPE_CREATE)}
                    component={props => <ScoreTypeListPage {...props} />}
                    permission={PERMISSION.TENANT_ADMIN_MENUS}
                  />
                  <PrivateRoute
                    exact
                    path={ROUTES.USER_GROUP_ROLE_PERMISSIONS}
                    component={props => <UserGroupRolePermissionPage {...props} />}
                    permission={PERMISSION.TENANT_ADMIN_MENUS}
                  />
                  <PrivateRoute
                    path={getPath(`${ROUTES.DISCOUNT_CODES}/create`)}
                    component={props => <DiscountCodeCreateForm {...props} />}
                    permission={PERMISSION.TENANT_ADMIN_MENUS}
                  />
                  <PrivateRoute
                    path={getPath(`${ROUTES.DISCOUNT_CODES}/edit/:key`)}
                    component={props => <DiscountCodeEditForm {...props} />}
                    permission={PERMISSION.TENANT_ADMIN_MENUS}
                  />
                  <PrivateRoute
                    path={getPath(ROUTES.DISCOUNT_CODES)}
                    component={props => <DiscountCodeList {...props} />}
                    permission={PERMISSION.TENANT_ADMIN_MENUS}
                  />

                  {/* System / Tenant Settings */}
                  <PrivateRoute
                    exact
                    path={ROUTES.SYSTEM_THEME}
                    component={props => <SystemSettingsTheme {...props} />}
                    permission={PERMISSION.TENANT_ADMIN_MENUS}
                  />
                  {/* Global Admin / FuseSport Employee Pages only */}
                  <PrivateRoute
                    path={getPath(ROUTES.TENANT, ROUTES.TENANT_CREATE)}
                    component={props => <TenantListPage {...props} />}
                    permission={PERMISSION.FUSESPORT_ADMIN}
                  />

                  {/* Reports */}
                  <PrivateRoute
                    exact
                    path={ROUTES.SUMMARY_REPORT}
                    component={props => <SummaryReportPage {...props} />}
                    permission={PERMISSION.ACCESS_SUMMARY_REPORT}
                  />

                  <PrivateRoute
                    exact
                    path={ROUTES.DISCOUNT_CODE_USAGE_REPORT}
                    component={props => <DiscountCodeUsageReportPage {...props} />}
                    permission={PERMISSION.ACCESS_DISCOUNT_REPORTS}
                  />

                  <PrivateRoute
                    exact
                    path={ROUTES.DISCOUNT_CODE_TRANSACTION_REPORT}
                    component={props => <DiscountCodeTransactionReportPage {...props} />}
                    permission={PERMISSION.ACCESS_DISCOUNT_REPORTS}
                  />

                  {/* Async downloads */}
                  <PrivateRoute
                    exact
                    path={ROUTES.DOWNLOAD}
                    component={props => <DownloadExportedFilesPage {...props} />}
                    permission={PERMISSION.DOWNLOAD_EXPORTED_FILES}
                  />

                  {/* File download */}
                  <PrivateRoute
                    path={ROUTES.PRIVATE_FILE}
                    component={props => <PrivateFilePage {...props} />}
                    permission={PERMISSION.DOWNLOAD_PRIVATE_FILES}
                  />
                  {/* Hidden Routes (which don't have any navigation link directing users to them) */}
                  <Route exact path="/test/form-field" component={FormFieldTestPage} />

                  {/* 404 */}
                  <Route component={NoMatchPage} />
                </Switch>
              </Layout.Content>
            </React.Suspense>
          </>
        )}
      </main>
    </Layout>
  );
}

export default App;
